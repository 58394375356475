import React, { useState, useEffect, useGlobal } from 'reactn';
import { Helmet } from 'react-helmet';
// components
import { fetchCourses } from '../axios';
import Loader from '../components/loader/loader';
import VideoArea from '../components/videoArea/videoArea';
import InfoSection from '../components/infoSection/infoSection';
import LogoSlider from '../components/logoSlider/logoSlider';
// Material UI
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  mainSection: {
    position: 'relative',
    zIndex: 2,
    marginBottom: '-20px',
    backgroundColor: '#edf4f7'
  }
});

const SchoolIndexHOC = () => {
  const classes = useStyles();

  const [sponsorsState] = useGlobal('sponsorsState');

  const [schoolCourses, setSchoolCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const courses = await fetchCourses();
      setSchoolCourses(courses);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Σχολή Ιστιοπλοΐας | Ιστιοπλοϊκός Όμιλος Πατρών</title>
      </Helmet>
      <Loader completed={!isLoading} />
      <VideoArea />
      <div className={classes.mainSection}>
        <InfoSection courses={schoolCourses} />
        <LogoSlider sponsors={sponsorsState.sponsors} />
      </div>
    </div>
  );
};

export default SchoolIndexHOC;
