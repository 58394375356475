import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Paper from '@material-ui/core/Paper';
// Material UI
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    padding: '10px 10px',
    minHeight: '300px',
    textAlign: 'center',
    margin: '10px'
  },
  centerText: {
    textAlign: 'center'
  }
});

const SchoolCourse = ({ title, body, image }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <img src={image} alt={title} width='50' />
      <h2>{title}</h2>
      {body ? <div>{ReactHtmlParser(body)}</div> : null}
    </Paper>
  );
};

export default SchoolCourse;
