import React, { useEffect, useState, useGlobal } from 'reactn';
import { Helmet } from 'react-helmet';
// axios
import { fetchPage } from '../axios';
import { transformInnerHTML } from '../config';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../components/loader/loader';
import Layout from '../components/layout/layout';
import TopBackground from '../components/topBackground/topBackground';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  mainWrapper: {
    overflowX: 'auto',
    padding: 20
  }
});

const BasicPage = ({ uuid }) => {
  const classes = useStyles();

  const [sponsorsState] = useGlobal('sponsorsState');

  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const requestedPage = await fetchPage(uuid);
      setPageData(requestedPage);
      setIsLoading(false);
    }
    uuid ? fetchData() : setIsLoading(false);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {pageData ? pageData.title + ' | ' : ''}Ιστιοπλοϊκός Όμιλος Πατρών
        </title>
      </Helmet>
      <Loader completed={!isLoading && !sponsorsState.fetchingSponsors} />
      <TopBackground />
      <Layout>
        <Paper className={classes.mainWrapper}>
          <h1
            dangerouslySetInnerHTML={{
              __html: pageData ? pageData.title : null
            }}
          />
          <div>
            {pageData
              ? ReactHtmlParser(pageData.body, {
                  transform: transformInnerHTML
                })
              : null}
          </div>
        </Paper>
      </Layout>
    </div>
  );
};

export default BasicPage;
