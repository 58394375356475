import React from 'react';
import YouTube from 'react-youtube';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  video: {
    margin: '40px 0'
  }
});

export default function Video() {
  const classes = useStyles();
  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      autoplay: 0,
      fs: 0,
      controls: 0
    }
  };

  return (
    <YouTube videoId='MkolXMMqfKk' opts={opts} className={classes.video} />
  );
}
