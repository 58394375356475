import React from 'react';
import './cornerRibbon.css';


const CornerRibbon = (props) => {
    const {text} = props;
    return (
            <div className="ribbon ribbon-top-right"><span>{text}</span></div>
    );
};

export default CornerRibbon;