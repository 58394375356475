import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
// Components
import { fetchPage } from '../axios';
import Layout from '../components/layout/layout';
import SchoolContactForm from '../components/forms/schoolContactForm';
import Loader from '../components/loader/loader';
import TopBackground from '../components/topBackground/topBackground';
// Material UI
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  mainWrapper: {
    overflowX: 'auto',
    padding: 20
  }
});

const SchoolContactPage = () => {
  const classes = useStyles();

  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const uuid = '3c6d012b-dd08-4def-a13e-fdbf42068799';

  useEffect(() => {
    async function fetchData() {
      const requestedPage = await fetchPage(uuid);
      setPageData(requestedPage);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Επικοινώνια με τη σχόλη ιστιοπλοΐας | Ιστιοπλοϊκός Όμιλος Πατρών
        </title>
      </Helmet>
      <Loader completed={!isLoading} />
      <TopBackground />
      <Layout>
        <Paper className={classes.mainWrapper}>
          <h1
            dangerouslySetInnerHTML={{
              __html: pageData ? pageData.title : null
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: pageData ? pageData.body : null
            }}
          />
          <SchoolContactForm />
        </Paper>
      </Layout>
    </div>
  );
};

export default SchoolContactPage;
