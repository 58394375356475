import React from 'react';
import { randomImage } from '../../config';
import makeStyles from '@material-ui/core/styles/makeStyles';

const TopBackground = () => {
  const useStyles = makeStyles({
    layer: {
      width: '100%',
      height: '400px',
      position: 'relative',
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
      background: `url(${randomImage()})`,
      backgroundColor: 'rgba(0,0,0,0.3)',
      backgroundPosition: 'center',
      backgroundBlendMode: 'darken'
    }
  });

  const classes = useStyles();

  return <div className={classes.layer}></div>;
};

export default TopBackground;
