import React from 'react';
import './heroArea.css';

class HeroArea extends React.Component {
  constructor(props) {
    super(props);

    this.changeTO = null;
    this.AUTOCHANGE_TIME = 4000;

    this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
  }

  componentWillUnmount() {
    window.clearTimeout(this.changeTO);
  }

  componentDidMount() {
    this.runAutochangeTO();
    setTimeout(() => {
      this.setState({ activeSlide: 0, sliderReady: true });
    }, 0);
  }

  runAutochangeTO() {
    this.changeTO = setTimeout(() => {
      this.changeSlides(1);
      this.runAutochangeTO();
    }, this.AUTOCHANGE_TIME);
  }

  changeSlides(change) {
    window.clearTimeout(this.changeTO);
    const { length } = this.props.slides;
    const prevSlide = this.state.activeSlide;
    let activeSlide = prevSlide + change;
    if (activeSlide < 0) activeSlide = length - 1;
    if (activeSlide >= length) activeSlide = 0;
    this.setState({ activeSlide, prevSlide });
  }

  render() {
    const { activeSlide, prevSlide, sliderReady } = this.state;
    const { slides } = this.props;
    return (
      <div className={!sliderReady ? 'slider' : 'slider s--ready'}>
        {/*<p className="slider__top-heading">Travelers</p>*/}
        <div className='slider__slides'>
          {slides && slides.length > 0
            ? slides.map((slide, index) => (
                <div
                  className={
                    activeSlide === index
                      ? 'slider__slide s--active'
                      : prevSlide === index
                      ? 'slider__slide s--prev'
                      : 'slider__slide'
                  }
                  key={index}
                >
                  <div className='slider__slide-content'>
                    <h3 className='slider__slide-heading'>
                      {<span>{slide.title ? slide.title : ""}</span>}
                    </h3>
                    <h2 className='slider__slide-subheading'>
                        <span>
                            {
                        slide.subTitle ?
                            slide.subTitle  :
                            slide.title ?
                                slide.title:
                                ""}
                                </span>
                    </h2>
                    {/*<p className="slider__slide-readmore">read more</p>*/}
                  </div>
                  <div className='slider__slide-parts'>
                    <div
                      className='slider__slide-part-inner'
                      style={{ backgroundImage: `url(${slide.image})` }}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
        {/*<div className="slider__control" onClick={() => this.changeSlides(-1)} />*/}
        {/*<div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} />*/}
      </div>
    );
  }
}

export default HeroArea;
