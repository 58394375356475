import React, { useGlobal } from 'reactn';
import Sponsor from '../sponsor/sponsor';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const Layout = ({ children }) => {
  const [sponsorsState] = useGlobal('sponsorsState');

  if (sponsorsState.fetchingSponsors) {
    return null;
  }
  return (
    <Container maxWidth='lg' style={{ paddingTop: 20 }}>
      <Grid container>
        <Grid item md={9} sm={12} xs={12}>
          {children}
        </Grid>
        <Grid item md={3} sm={12} xs={12} style={{ marginTop: 25 }}>
          {sponsorsState.sponsors.length > 0
            ? sponsorsState.sponsors
                .filter(sponsor => sponsor.isRight)
                .map((sponsor, index) => (
                  <Sponsor
                    key={index}
                    title={sponsor.title}
                    url={sponsor.url}
                    image={sponsor.image}
                    isGolden={sponsor.priority === 1 ? true : false}
                  />
                ))
            : null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Layout;
