import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// Logo
import logo from '../../assets/img/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 1101,
    backgroundColor: theme.palette.primary.lighten
  },
  rootZoomOut: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1101,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    animation: '$zoomOut 1s ease-in-out'
  },
  centered: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px'
  },
  logo: {
    width: '100px',
    animation: '$bounce 1s infinite alternate'
  },
  progressBar: {
    width: '100%',
    position: 'absolute',
    top: '125px',
    marginLeft: '-70px'
  },
  '@keyframes bounce': {
    from: {
      transform: 'translateY(0px)'
    },
    to: {
      transform: 'translateY(-15px)'
    }
  },
  '@keyframes zoomOut': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '60%': {
      transform: 'scale(0)',
      opacity: 0
    }
  }
}));

const Loader = props => {
  const { completed } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isCompleted, setCompleted] = useState(false);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (completed) {
      setAnimation(true);
      setTimeout(() => {
        setCompleted(true);
      }, 450);
    }
  }, [completed]);

  return (
    <React.Fragment>
      {!isCompleted && (
        <div className={!animation ? classes.root : classes.rootZoomOut}>
          <div className={classes.centered}>
            <img className={classes.logo} src={logo} />
            {!animation && (
              <CircularProgress
                className={classes.progressBar}
                color='primary'
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;
