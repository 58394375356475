import React, { useState, useEffect, useGlobal } from 'reactn';
import { Helmet } from 'react-helmet';
// axios
import { fetchSchoolAnnouncements } from '../axios';
// components
import Loader from '../components/loader/loader';
import Layout from '../components/layout/layout';
import NewsCard from '../components/newsCard/newsCard';
import Paginator from '../components/paginator/paginator';
import TopBackground from '../components/topBackground/topBackground';
// Material-UI
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { resultsPerPage } from '../config';

const useStyles = makeStyles({
  mainWrapper: {
    padding: 20
  },
  loader: {
    minHeight: 200,
    textAlign: 'center'
  }
});

const SchoolAnnouncementsPage = ({ match }) => {
  const classes = useStyles();

  const [last, setLast] = useState(0);

  const [sponsorsState] = useGlobal('sponsorsState');
  const [articlesState, setArticlesState] = useGlobal('announcementsState');

  const page = match.params.page ? parseInt(match.params.page) : 1;

  useEffect(() => {
    async function fetchData() {
      setArticlesState({
        ...articlesState,
        fetchingArticles: true
      });

      const [
        articles,
        hasNextPage,
        totalCount
      ] = await fetchSchoolAnnouncements(page);
      setLast(Math.floor(parseInt(totalCount / resultsPerPage) + 1));
      setArticlesState({
        ...articlesState,
        fetchingArticles: false,
        articles: articles
      });
    }

    fetchData();
  }, [fetchSchoolAnnouncements, page]);

  return (
    <div>
      <Helmet>
        <title>
          Ανακοινώσεις - Δελτία Τύπου Σχολής Ιστιοπλοΐας | Ιστιοπλοϊκός Όμιλος
          Πατρών
        </title>
      </Helmet>
      <Loader
        completed={
          !sponsorsState.fetchingSponsors && !articlesState.fetchingArticles
        }
      />
      <TopBackground />
      <Layout>
        {articlesState.fetchingArticles && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        {!articlesState.fetchingArticles && articlesState.articles.length > 0
          ? articlesState.articles.map((article, index) => (
              <NewsCard
                key={index}
                uuid={article.id}
                title={article.title}
                image={article.image}
                description={article.body}
                type='school'
              />
            ))
          : null}
        <Paginator
          previous={page - 1}
          page={page}
          last={last >= page + 1 ? last : 0}
        />
      </Layout>
    </div>
  );
};

export default SchoolAnnouncementsPage;
