import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  banner: {
    width: '350px',
    maxWidth: '90vw',
    padding: '15px',
    borderRadius: '10px',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: '100',
    boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
  },
  bannerText: {
    textAlign: 'justify',
    marginBottom: '10px'
  },
  bannerBtnsWrapper: {
    width: '100%'
  },
  bannerBtns: {
    width: '40%',
    margin: '0 5%'
  },
  btnLink: {
    textDecoration: 'none'
  }
});

const CookieNotification = () => {
  const classes = useStyles();

  const [accepted, setAccepted] = useState(false);

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  const getCookie = cname => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const onAcceptCookies = () => {
    setCookie('accept-cookies', 1, 30);
    setAccepted(true);
  };

  useEffect(() => {
    if (getCookie('accept-cookies') == 1) setAccepted(true);
  }, []);

  if (accepted) return null;
  return (
    <Paper className={classes.banner}>
      <div className={classes.bannerText}>
        Η συγκεκριμένη ιστοσελίδα χρησιμοποιεί cookies για να βελτιώσει την
        εμπειρία σας και να σας προσφέρει καλύτερες υπηρεσίες.{' '}
      </div>
      <div className={classes.bannerBtnsWrapper}>
        <Button
          className={classes.bannerBtns}
          variant='contained'
          color='primary'
          onClick={onAcceptCookies}
        >
          Αποδοχή
        </Button>
        <Link to='/terms' className={classes.btnLink}>
          <Button
            className={classes.bannerBtns}
            variant='contained'
            color='primary'
          >
            Περισσότερα
          </Button>
        </Link>
      </div>
    </Paper>
  );
};

export default CookieNotification;
