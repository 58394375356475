import React, { useEffect, useState, useGlobal } from 'reactn';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
// axios
import { fetchArticle } from '../axios';
import { transformInnerHTML } from '../config';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../components/loader/loader';
import Layout from '../components/layout/layout';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import defaultImg from '../../src/assets/img/defaultPost.jpg';
import TopBackground from '../components/topBackground/topBackground';

const useStyles = makeStyles({
  mainWrapper: {
    overflowX: 'auto',
    padding: 20
  },
  postHeader: {
    marginBottom: 20
  },
  articleTitle: {
    padding: '20px 0 5px 0'
  },
  articleImgWrapper: {
    textAlign: 'center'
  },
  articleImg: {
    maxWidth: '100%'
  }
});

const ArticlePage = ({ history, match }) => {
  const classes = useStyles();

  const [sponsorsState] = useGlobal('sponsorsState');
  const [articlesState] = useGlobal('articlesState');

  const [goBackEnabled, setGoBackEnabled] = useState(true);
  const [articleData, setArticleData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const uuid = match.params.uuid;

  useEffect(() => {
    async function fetchData() {
      let article = articlesState.articles.find(article => article.id === uuid);
      if (!article) {
        setGoBackEnabled(false);
        article = await fetchArticle(uuid);
      }
      setArticleData(article);
      setIsLoading(false);
    }
    uuid ? fetchData() : setIsLoading(false);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {articleData ? articleData.title + ' | ' : ''}Ιστιοπλοϊκός Όμιλος
          Πατρών
        </title>
      </Helmet>
      <Loader completed={!isLoading && !sponsorsState.fetchingSponsors} />
      <TopBackground />
      <Layout>
        <Paper className={classes.mainWrapper}>
          <Grid
            className={classes.postHeader}
            container
            spacing={2}
            direction='row-reverse'
            alignItems='center'
          >
            <Grid item xs={12} sm={12} md={6}>
              <h2
                className={classes.articleTitle}
                dangerouslySetInnerHTML={{
                  __html: articleData ? articleData.title : null
                }}
              />
              <i>{articleData ? 'Ανέβηκε στις ' + articleData.date : null}</i>
            </Grid>
            <Grid
              className={classes.articleImgWrapper}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <img
                className={classes.articleImg}
                src={
                  articleData && articleData.image
                    ? articleData.image
                    : defaultImg
                }
                alt={articleData ? articleData.title : null}
              />
            </Grid>
          </Grid>
          <div>
            {articleData
              ? ReactHtmlParser(articleData.body, {
                  transform: transformInnerHTML
                })
              : null}
          </div>
          {goBackEnabled ? (
            <Tooltip title='Πίσω στα άρθρα'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon />
              </Button>
            </Tooltip>
          ) : null}
        </Paper>
      </Layout>
    </div>
  );
};

export default withRouter(ArticlePage);
