import React, {useEffect, useGlobal, setGlobal} from 'reactn';
import {BrowserRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';
// axios
import {fetchSponsors} from './axios';
// HOCS
import FirstPageHOC from './containers/firstPageHOC';
import SchoolIndexHOC from './containers/schoolIndexHOC';
import BasicPage from './containers/basicPage';
import AnnouncementsPage from './containers/announcementsPage';
import SchoolAnnouncementsPage from './containers/schoolAnnouncementsPage';
import ArticlePage from './containers/articlePage';
import SchoolArticlePage from './containers/schoolArticlePage';
import contactPage from './containers/contactPage';
import schoolContactPage from './containers/schoolContactPage';
import PageNotFound from './containers/pageNotFound';
// Components
import Header from './components/header/header';
import HeaderLinks from './components/header/headerLinks';
import Footer from './components/footer/footer';
import ScrollHandler from './components/scrollHandler/scrollHandler';
import CookieNotification from './components/cookieNotification/cookieNotification';
// Styles
import {makeStyles, createMuiTheme, useTheme} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import {menuLinksSite, menuLinksSchool} from './config';

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Open Sans'].join(',')
    },
    palette: {
        primary: {
            main: '#5C80BC',
            darken: '#2F4858',
            lighten: '#B8D4E3',
            lighten2: '#edf4f7'
        }
    }
});

setGlobal({
    articlesState: {
        fetchingArticles: true,
        articles: [],
        articlesErrorMessage: null
    },
    announcementsState: {
        fetchingArticles: true,
        articles: [],
        articlesErrorMessage: null
    },
    sponsorsState: {
        fetchingSponsors: true,
        sponsors: [],
        sponsorsErrorMessage: null
    },
    slideshowState: {
        fetchingSlide: true,
        slide: [],
        slideErrorMessage: null
    }
});

const history = createBrowserHistory();

/* Google analytics page view tracking */
ReactGA.initialize('UA-127966790-5');
const tracker = ({history}) => {
    // console.log('tracking', location.pathname)
    ReactGA.set({page: history.location.pathname})
    ReactGA.pageview(history.location.pathname)
    return null
}


const useStyles = makeStyles({
    root: {
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: '#edf4f7',
        paddingBottom: '200px'
    }
});

const App = () => {
    const classes = useStyles();

    const [sponsorsState, setSponsorsState] = useGlobal('sponsorsState');

    useEffect(() => {
        async function fetchData() {
            setSponsorsState({
                ...sponsorsState,
                fetchingSponsors: true
            });

            const sponsors = await fetchSponsors();
            setSponsorsState({
                ...sponsorsState,
                fetchingSponsors: false,
                sponsors: sponsors
            });
        }

        fetchData();
    }, [fetchSponsors]);


    const routes = [...menuLinksSite, ...menuLinksSchool];

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <Router history={history}>
                    <CookieNotification/>
                    <ScrollHandler/>
                    <Header
                        color='transparent'
                        rightLinks={<HeaderLinks/>}
                        fixed
                        changeColorOnScroll={{
                            height: 200,
                            color: 'white'
                        }}
                    />
                    <Route render={tracker}/>
                    <Switch>
                        <Route exact path='/404' component={PageNotFound}/>
                        {/* Custom React pages. These ones will be created by us (e.g. index pages, announcements, contact...) */}
                        <Route exact path='/' component={FirstPageHOC}/>
                        <Route exact path='/school' component={SchoolIndexHOC}/>

                        <Route
                            exact
                            path='/announcements/:page?'
                            component={AnnouncementsPage}
                        />
                        <Route
                            exact
                            path='/school/news/:page?'
                            component={SchoolAnnouncementsPage}
                        />

                        <Route exact path='/article/:uuid' component={ArticlePage}/>
                        <Route
                            exact
                            path='/school/article/:uuid'
                            component={SchoolArticlePage}
                        />

                        <Route exact path='/contact' component={contactPage}/>
                        <Route exact path='/school/contact' component={schoolContactPage}/>

                        {/* Drupal-fetched pages. These ones will be fetched directly from drupal and rendered inside the BasicPage component */}
                        {routes.map((elem, index) => {
                            if (elem.hasOwnProperty('submenu')) {
                                return elem.submenu.map((subElem, index) => {
                                    if (subElem.basicPage)
                                        return (
                                            <Route
                                                key={index}
                                                exact
                                                path={subElem.url}
                                                render={() => <BasicPage uuid={subElem.uuid}/>}
                                            />
                                        );
                                });
                            } else {
                                if (elem.basicPage) {
                                    return (
                                        <Route
                                            key={index}
                                            exact
                                            path={elem.url}
                                            render={() => <BasicPage uuid={elem.uuid}/>}
                                        />
                                    );
                                } else if (elem.isExternal) {
                                    return (
                                        <Route
                                            render={() => {
                                                window.location.replace(elem.url);
                                                return null;
                                            }}
                                        />
                                    )
                                }
                            }
                        })}
                        <Redirect to="/404"/>
                    </Switch>
                    <Footer/>
                </Router>
            </div>
        </ThemeProvider>
    );
};

export default App;
