import React, { Fragment, useEffect, useGlobal } from 'reactn';
import { Helmet } from 'react-helmet';
// axios
import { fetchArticles, fetchSlideshow } from '../axios';
// components
import Layout from '../components/layout/layout';
import HeroArea from '../components/heroArea/heroArea';
import NewsCard from '../components/newsCard/newsCard';
import LogoSlider from '../components/logoSlider/logoSlider';
import Video from '../components/video/video';
import Loader from '../components/loader/loader';
// Material-UI
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  loader: {
    minHeight: 200,
    textAlign: 'center'
  }
});

const FirstPageHOC = () => {
  const classes = useStyles();

  const [articlesState, setArticlesState] = useGlobal('articlesState');
  const [slideshowState, setSlideshowState] = useGlobal('slideshowState');
  const [sponsorsState] = useGlobal('sponsorsState');

  const findHotArticle = articles => {
    let stickyIndex = 0;
    let foundSticky = false;
    for (let i = 0; i < articles.length; i++) {
      if (articles[i].sticky) {
        if (foundSticky) {
          articles[i].sticky = false;
          continue;
        }
        stickyIndex = i;
        foundSticky = true;
      }
    }

    articles = [
      articles[stickyIndex],
      ...articles.slice(0, stickyIndex),
      ...articles.slice(stickyIndex + 1)
    ];

    return articles;
  };

  useEffect(() => {
    async function fetchData() {
      setArticlesState({
        ...articlesState,
        fetchingArticles: true
      });
      const res = await fetchArticles();
      const articles = findHotArticle(res[0]);
      setArticlesState({
        ...articlesState,
        fetchingArticles: false,
        articles: articles
      });

      if (slideshowState.slide.length === 0) {
        setSlideshowState({
          ...slideshowState,
          fetchingSlide: true
        });
        const slideShow = await fetchSlideshow();
        setSlideshowState({
          ...slideshowState,
          fetchingSlide: false,
          slide: slideShow
        });
      }
    }
    fetchData();
  }, [fetchArticles, fetchSlideshow]);

  return (
    <Fragment>
      <Helmet>
        <title>Ιστιοπλοϊκός Όμιλος Πατρών | Ανακοινώσεις - Δελτία Τύπου</title>
      </Helmet>
      <Loader
        completed={
          !articlesState.fetchingArticles &&
          !slideshowState.fetchingSlide &&
          !sponsorsState.fetchingSponsors
        }
      />
      <HeroArea slides={slideshowState.slide} />
      <Layout>
        {articlesState.fetchingArticles && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        {!articlesState.fetchingArticles && articlesState.articles.length > 0
          ? articlesState.articles.map((article, index) => (
              <NewsCard
                key={index}
                uuid={article.id}
                title={article.title}
                image={article.image}
                description={article.body}
                isTop={article.sticky}
                type='club'
              />
            ))
          : null}
        <Video />
      </Layout>
      <LogoSlider sponsors={sponsorsState.sponsors} />
    </Fragment>
  );
};

export default FirstPageHOC;
