import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles({
    videoWrapper: {
        height: '70vh',
        position: 'relative',
        zIndex: 1,
        backgroundColor: 'rgba(0,0,0,0.2)',

    },
    video: {
        position: 'fixed',
        left: 0,
        top: 0,
        minWidth: '100%',
        height: '70vh',
        objectFit: 'cover',
        mixBlendMode: 'overlay'
    },
    headerTextBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -35%)",
        backfaceVisibility: "hidden",
        textAlign: "center",
    },
    headingPrimary: {
        color: "#fff",
        textTransform: "uppercase",
        marginBottom: "4rem"
    },
    headingPrimaryMainDesktop: {
        display: "block",
        fontSize: "50px",
        letterSpacing: "25px",
        fontWeight: "400",
        animationName: "$moveInLeft",
        animationDuration: "1s",
        animationTimingFunction: "ease-out",
        /*animationIteration-count: 3;*/
        /*animationDelay: 3s;*/
    },
    headingPrimarySubDesktop: {
        display: "block",
        fontWeight: "700",
        fontSize: "25px",
        animation: "$moveInRight 1s ease-out"
    },
    headingPrimaryMainMobile: {
        display: "block",
        fontSize: "30px",
        letterSpacing: "15px",
        fontWeight: "400",
        animationName: "$moveInLeft",
        animationDuration: "1s",
        animationTimingFunction: "ease-out",
        /*animationIteration-count: 3;*/
        /*animationDelay: 3s;*/
    },
    headingPrimarySubMobile: {
        display: "block",
        fontWeight: "700",
        fontSize: "15px",
        animation: "$moveInRight 1s ease-out"
    },
    headingPrimaryMainMobile370: {
        display: "block",
        fontSize: "15px",
        letterSpacing: "10px",
        fontWeight: "400",
        animationName: "$moveInLeft",
        animationDuration: "1s",
        animationTimingFunction: "ease-out",
        /*animationIteration-count: 3;*/
        /*animationDelay: 3s;*/
    },
    headingPrimarySubMobile370: {
        display: "block",
        fontWeight: "700",
        fontSize: "9px",
        animation: "$moveInRight 1s ease-out"
    },


    '@keyframes moveInLeft': {
        '0%': {
            opacity: '0',
            transform: 'translateX(-10rem)'
        },
        '80%': {
            transform: 'translateX(1rem)'
        },
        '100%': {
            opacity: '1',
            transform: 'translateX(0)'
        }
    },

    '@keyframes moveInRight': {
        '0%': {
            opacity: '0',
            transform: 'translateX(10rem)'
        },
        '80%': {
            transform: 'translateX(-1rem)'
        },
        '100%': {
            opacity: '1',
            transform: 'translateX(0)'
        }
    },

    '@keyframes moveInBottom': {
        '0%': {
            opacity: '0',
            transform: 'translateY(3rem)'
        },
        '100%': {
            opacity: '1',
            transform: 'translateX(0)'
        }
    }

});

const VideoArea = () => {
    const classes = useStyles();
    const matches650 = useMediaQuery('(max-width: 650px)');
    const matches370 = useMediaQuery('(max-width: 370px)');
    return (
        <div className={classes.videoWrapper}>
            <video autoPlay muted loop className={classes.video}>
                <source
                    src='https://d.sailingclub.gr/sites/default/files/iop-media/video_sm.mp4'
                    type='video/mp4'
                />
            </video>
            <div className={classes.headerTextBox} >
                <h1 className={classes.headingPrimary} >
                    <span className={matches370 ? classes.headingPrimaryMainMobile370 : !matches650 ? classes.headingPrimaryMainDesktop : classes.headingPrimaryMainMobile}> ΣΧΟΛΗ ΙΣΤΙΟΠΛΟΪΑΣ</span>
                    <span className={matches370 ? classes.headingPrimarySubMobile370 : !matches650 ? classes.headingPrimarySubDesktop : classes.headingPrimarySubMobile}> Ο ΔΡΟΜΟΣ ΓΙΑ ΤΗ ΘΑΛΑΣΣΑ</span>
                </h1>
            </div>
        </div>
    );
};

export default VideoArea;
