import React, { useState } from 'react';
// axios
import { sendFormDataContact, sendFormDataSchoolContact } from '../../axios';
// Material UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import SnackBarContentWrapper from '../snackBarContentWrapper/snackBarContentWrapper';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import ReCaptcha from 'react-google-invisible-recaptcha';
import _ from 'lodash';
import { captchaSitekey } from '../../config/index';

const useStyles = makeStyles({
  inputField: {
    margin: '5px 0 10px 0',
    width: '100%'
  },
  submitBtn: {
    margin: '10px 0'
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    /* bring your own prefixes */
    transform: 'translate(-50%, -50%)',
    zIndex: '5000'
  }
});

const ContactForm = () => {
  const classes = useStyles();
  const recaptcha = React.useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [email, setEmail] = React.useState({
    sendingEmail: false,
    successMessage: null,
    errorMessage: null
  });

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  const onSubmit = async token => {
    setEmail({
      ...email,
      sendingEmail: true
    });

    const emailResponse = await sendFormDataContact(token, formData);

    if (emailResponse.status !== 200 || !emailResponse.data.sid) {
      if (emailResponse.data.error) {
        setEmail({
          ...email,
          sendingEmail: false,
          errorMessage: _.toArray(emailResponse.data.error)
        });
      } else {
        setEmail({
          ...email,
          sendingEmail: false,
          errorMessage: ['Αδύνατη η υποβολή της φόρμας. Προσπαθήστε αργότερα.']
        });
      }
    } else {
      setEmail({
        ...email,
        sendingEmail: false,
        successMessage: ['Η φόρμα στάλθηκε με επιτυχία.']
      });
      clearForm();
    }
  };

  const clearForm = () => {
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  const handleClose = () => {
    setEmail({
      ...email,
      successMessage: null,
      errorMessage: null
    });
  };

  const onResolved = e => {
    e.preventDefault();

    recaptcha.current.execute().then(token => onSubmit(token));
  };

  return (
    <form onSubmit={e => onResolved(e)}>
      {email.sendingEmail && (
        <CircularProgress color={'primary'} className={classes.loader} />
      )}
      <TextField
        className={classes.inputField}
        label='Ονοματεπώνυμο'
        name='name'
        type='text'
        required
        onChange={onChange}
        value={formData && formData.name ? formData.name : ''}
      />
      <TextField
        className={classes.inputField}
        label='Διεύθυνση email'
        name='email'
        type='email'
        required
        value={formData && formData.email ? formData.email : ''}
        onChange={onChange}
      />
      <TextField
        className={classes.inputField}
        label='Θέμα'
        name='subject'
        type='text'
        required
        value={formData && formData.subject ? formData.subject : ''}
        onChange={onChange}
      />
      <TextField
        className={classes.inputField}
        label='Μήνυμα'
        name='message'
        type='text'
        multiline
        rows='4'
        value={formData && formData.message ? formData.message : ''}
        required
        onChange={onChange}
      />
      <ReCaptcha ref={recaptcha} sitekey={captchaSitekey} />
      <Button
        className={classes.submitBtn}
        type='submit'
        variant='contained'
        color='primary'
      >
        ΥΠΟΒΟΛΗ
      </Button>
      {email.errorMessage && (
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={Boolean(email.errorMessage)}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
        >
          <SnackBarContentWrapper
            onClose={handleClose}
            variant='error'
            message={
              <ul>
                {email.errorMessage.map(message => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            }
          />
        </Snackbar>
      )}
      {email.successMessage && (
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={Boolean(email.successMessage)}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
        >
          <SnackBarContentWrapper
            onClose={handleClose}
            variant='success'
            message={
              <ul>
                {email.successMessage.map(message => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            }
          />
        </Snackbar>
      )}
    </form>
  );
};

export default ContactForm;
