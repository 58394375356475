/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import CustomDropdown from '../customDropdown/customDropdown';
// menu links
import { menuLinksSite, menuLinksSchool } from '../../config';
// styles
import styles from './headerLinksStyle.js';

const useStyles = makeStyles(styles);

const HeaderLinks = ({ location }) => {
  const classes = useStyles();

  const [menuLinks, setMenuLinks] = useState(menuLinksSite);

  useEffect(() => {
    const urlTop = location.pathname.split('/').filter(x => x)[0];
    if (urlTop == 'school') {
      setMenuLinks(menuLinksSchool);
    } else {
      setMenuLinks(menuLinksSite);
    }
  }, [location]);

  return (
    <List className={classes.list}>
      {menuLinks.map((elem, index) => {
        if (elem.hasOwnProperty('noshow')) return null;
        return (
          <ListItem key={index} className={classes.listItem}>
            {elem.hasOwnProperty('title') ? (
              <CustomDropdown
                noLiPadding
                buttonText={elem.title}
                buttonProps={{
                  className: classes.navLink,
                  color: 'transparent'
                }}
                dropdownList={elem.submenu.map(subElement => {
                  return (
                    <Link to={subElement.url} className={classes.dropdownLink}>
                      {subElement.name}
                    </Link>
                  );
                })}
              />
            ) : (
              <Link
                to={elem.url}
                color='transparent'
                className={classes.navLink}
              >
                {elem.name}
              </Link>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default withRouter(HeaderLinks);
