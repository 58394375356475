import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const maxSponsors = 20;

const useStyles = makeStyles({
  slider: {
    background: 'white',
    boxShadow: '0 10px 20px -5px rgba(0, 0, 0, 0.125)',
    height: '150px',
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    '&:before': {
      background:
        'linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%)',
      content: '',
      height: '150px',
      position: 'absolute',
      width: '200px',
      zIndex: 2,
      left: 0,
      top: 0
    },
    '&:after': {
      background:
        'linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%)',
      content: '',
      height: '150px',
      position: 'absolute',
      width: '200px',
      zIndex: 2,
      right: 0,
      top: 0,
      transform: 'rotateZ(180deg)'
    }
  },
  slideTrack: {
    animation: '$scrollLogoSlider ' + 6 * maxSponsors + 's linear infinite',
    display: 'flex',
    width: maxSponsors * 500 + 'px'
  },
  slide: {
    height: 150,
    width: 250,
    padding: '10px 10px',
    textAlign: 'center'
  },
  '@keyframes scrollLogoSlider': {
    '0%': {
      transform: 'translateX(0)'
    },
    '100%': {
      transform: 'translateX(' + -250 * maxSponsors + 'px)'
    }
  }
});

const LogoSlider = ({ sponsors }) => {
  const classes = useStyles();
  const [sponsorsFilledToMax, setSponsorsFilledToMax] = useState([]);

  useEffect(() => {
    if (sponsors.length === 0) return;
    let tempArray = [];
    let j = 0;
    for (let i = 0; i < 2 * maxSponsors; i++, j++) {
      j = j === sponsors.length ? 0 : j;
      tempArray.push(sponsors[j]);
    }
    setSponsorsFilledToMax(tempArray);
  }, [sponsors]);

  return (
    <div className={classes.slider}>
      <div className={classes.slideTrack}>
        {sponsorsFilledToMax.length > 0
          ? sponsorsFilledToMax.map((sponsor, index) => (
              <div key={index} className={classes.slide}>
                <a href={sponsor.url} target='_blank' rel='noopener noreferrer'>
                  <img src={sponsor.image} height='130' alt={sponsor.title} />
                </a>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default LogoSlider;
