import axios from 'axios';
import { baseUrl, resultsPerPage } from '../config/index';

axios.defaults.baseURL = baseUrl;

const articlesParser = data => {
  const images = data.included
    ? data.included.map(articleImage => articleImage)
    : [];
  let articles = data.data.map(articleImage => articleImage);

  articles = articles.map(article => ({
    id: article.id,
    date: new Date(article.attributes.created).toLocaleDateString('gr-GR'),
    sticky: article.attributes.sticky,
    title: article.attributes.title,
    body: article.attributes.body ? article.attributes.body.processed : null,
    image: article.relationships.field_image.data
      ? baseUrl +
        images.find(
          image => image.id === article.relationships.field_image.data.id
        ).attributes.uri.url
      : null
  }));

  return articles;
};

const articleParser = data => {
  const article = {
    id: data.data.id,
    date: new Date(data.data.attributes.created).toLocaleDateString('gr-GR'),
    sticky: data.data.attributes.sticky,
    title: data.data.attributes.title,
    body: data.data.attributes.body
      ? data.data.attributes.body.processed
      : null,
    image: data.included ? baseUrl + data.included[0].attributes.uri.url : null
  };

  return article;
};

const schoolAnnouncementsParser = data => {
  const images = data.included
    ? data.included.map(articleImage => articleImage)
    : [];
  let articles = data.data.map(articleImage => articleImage);

  articles = articles.map(article => ({
    id: article.id,
    date: new Date(article.attributes.created).toLocaleDateString('gr-GR'),
    sticky: article.attributes.sticky,
    title: article.attributes.title,
    body: article.attributes.body ? article.attributes.body.processed : null,
    image: article.relationships.field_school_image.data
      ? baseUrl +
        images.find(
          image => image.id === article.relationships.field_school_image.data.id
        ).attributes.uri.url
      : null
  }));

  return articles;
};

const schoolAnnouncementParser = data => {
  const article = {
    id: data.data.id,
    date: new Date(data.data.attributes.created).toLocaleDateString('gr-GR'),
    sticky: data.data.attributes.sticky,
    title: data.data.attributes.title,
    body: data.data.attributes.body
      ? data.data.attributes.body.processed
      : null,
    image: data.included ? baseUrl + data.included[0].attributes.uri.url : null
  };

  return article;
};

const sponsorsParser = data => {
  const images = data.included.map(sponsorImage => sponsorImage);
  let sponsors = data.data.map(sponsorImage => sponsorImage);

  sponsors = sponsors.map(sponsor => ({
    id: sponsor.id,
    title: sponsor.attributes.title,
    url: sponsor.attributes.field_url_sponsor,
    isRight: sponsor.attributes.field_isright_sponsor == 'true',
    priority: sponsor.attributes.field_priority_sponsor,
    image: sponsor.relationships.field_image_sponsor.data
      ? baseUrl +
        images.find(
          image =>
            image.id === sponsor.relationships.field_image_sponsor.data.id
        ).attributes.uri.url
      : null
  }));

  return sponsors;
};

const slidesParser = data => {
  const slidesImages = data.included.map(slidesImage => slidesImage);
  let slides = data.data.map(slide => slide);

  slides = slides.map(slide => ({
    id: slide.id,
    date: slide.attributes.created,
    subTitle: slide.attributes.field_subtitle_slider,
    title: slide.attributes.field_title_slider,
    image: slide.relationships.field_image_slider.data
      ? baseUrl +
        slidesImages.find(
          image => image.id === slide.relationships.field_image_slider.data.id
        ).attributes.uri.url
      : null
  }));

  return slides;
};

const pageDataParser = data => {
  const pageData = {
    id: data.data.id,
    title: data.data.attributes.title,
    body: data.data.attributes.body.processed
      ? data.data.attributes.body.processed
      : null
  };

  return pageData;
};

const coursesParser = data => {
  const coursesImages = data.included.map(coursesImage => coursesImage);
  let courses = data.data.map(course => course);

  courses = courses.map(course => ({
    title: course.attributes.title,
    body: course.attributes.body.processed
      ? course.attributes.body.processed
      : null,
    image: course.relationships.field_course_image.data
      ? baseUrl +
        coursesImages.find(
          image => image.id === course.relationships.field_course_image.data.id
        ).attributes.uri.url
      : null
  }));

  return courses;
};

export const fetchArticles = async page => {
  const offset = (page - 1) * resultsPerPage;
  const response = await axios.get(
    `/jsonapi/node/article?include=field_image&sort=-created&page[limit]=${resultsPerPage}&page[offset]=${offset}`
  );

  const hasNext = !!response.data.links.next;
  const totalCount = response.data.meta.count;

  const parsedData = articlesParser(response.data);

  return [parsedData, hasNext, totalCount];
};

export const fetchArticle = async uuid => {
  const response = await axios.get(
    `/jsonapi/node/article/${uuid}?include=field_image`
  );

  const parsedData = articleParser(response.data);

  return parsedData;
};

export const fetchSchoolAnnouncements = async page => {
  const offset = (page - 1) * resultsPerPage;
  const response = await axios.get(
    `/jsonapi/node/school_announcement?include=field_school_image&sort=-created&page[limit]=${resultsPerPage}&page[offset]=${offset}`
  );

  const hasNext = !!response.data.links.next;
  const totalCount = response.data.meta.count;

  const parsedData = schoolAnnouncementsParser(response.data);

  return [parsedData, hasNext, totalCount];
};

export const fetchSchoolAnnouncement = async uuid => {
  const response = await axios.get(
    `/jsonapi/node/school_announcement/${uuid}?include=field_school_image`
  );

  const parsedData = schoolAnnouncementParser(response.data);

  return parsedData;
};

export const fetchSponsors = async () => {
  const response = await axios.get(
    '/jsonapi/node/sponsors?include=field_image_sponsor&sort=field_priority_sponsor'
  );

  const parsedData = sponsorsParser(response.data);

  return parsedData;
};

export const fetchSlideshow = async () => {
  const response = await axios.get(
    '/jsonapi/node/slide_show?include=field_image_slider&sort=field_slide_priority'
  );

  const parsedData = slidesParser(response.data);

  return parsedData;
};

export const fetchPage = async uuid => {
  const response = await axios.get('/jsonapi/node/page/' + uuid);

  const parsedData = pageDataParser(response.data);

  return parsedData;
};

export const fetchCourses = async () => {
  const response = await axios.get(
    '/jsonapi/node/school_course?include=field_course_image&sort=field_priority'
  );

  const parsedData = coursesParser(response.data);

  return parsedData;
};

export const schoolRegistrationSubmitForm = async form => {
  const response = await axios({
    method: 'post',
    url: '/webform_rest/submit',
    data: {
      webform_id: 'school_registration',
      name: form.name,
      email: form.email
    }
  });
  return response;
};

export const sendFormDataRace = async data => {
  const requestData = {
    ...data,
    webform_id: 'race_registration'
  };

  const res = await axios.post('/webform_rest/submit', requestData);
  return res;
};

export const sendFormDataContact = async (token, data) => {
  const captchaVerify = await axios.get(
    `/captcha_verify?_format=json&response=${token}`
  );

  const captchaRes = JSON.parse(captchaVerify.data);

  if (!captchaRes.success || captchaRes.score < 0.5) return captchaVerify;

  const requestData = {
    ...data,
    webform_id: 'contact'
  };

  const res = await axios.post('/webform_rest/submit', requestData);

  return res;
};

export const sendFormDataSchoolContact = async (token, data) => {
  const captchaVerify = await axios.get(
    `/captcha_verify?_format=json&response=${token}`
  );

  const captchaRes = JSON.parse(captchaVerify.data);

  if (!captchaRes.success || captchaRes.score < 0.5) return captchaVerify;

  const requestData = {
    ...data,
    webform_id: 'school_contact'
  };

  //try {
  const res = await axios.post('/webform_rest/submit', requestData);
  return res;
  //} catch (err) {
  //console.log(err);
  //}
};
