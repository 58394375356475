import React from 'react';
import { Link } from 'react-router-dom';
// Material-UI
import LastPageRoundedIcon from '@material-ui/icons/LastPageRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import FirstPageRoundedIcon from '@material-ui/icons/FirstPageRounded';

const useStyles = makeStyles({
  buttonLink: {
    marginRight: '20px'
  },
  simpleLink: {
    textDecoration: 'none',
    color: '#2F4858'
  }
});

const Paginator = ({ previous, page, last }) => {
  const classes = useStyles();

  return (
    <div>
      {page > 1 ? (
        <Tooltip title='Πρωτη σελιδα'>
          <IconButton>
            <Link to={'/announcements/'} className={classes.simpleLink}>
              <FirstPageRoundedIcon fontSize='large' />
            </Link>
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled>
          <FirstPageRoundedIcon fontSize='large' />
        </IconButton>
      )}
      {previous > 0 ? (
        <Tooltip title='Προηγουμενη σελιδα'>
          <IconButton>
            <Link
              to={'/announcements/' + previous}
              className={classes.simpleLink}
            >
              <ArrowBackIosRoundedIcon fontSize='large' />
            </Link>
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled>
          <ArrowBackIosRoundedIcon fontSize='large' />
        </IconButton>
      )}
      {' ' + page + ' '}
      {last > 0 && page <= last ? (
        <Tooltip title='Επομενη σελιδα'>
          <IconButton>
            <Link
              to={'/announcements/' + (page + 1)}
              className={classes.simpleLink}
            >
              <ArrowForwardIosRoundedIcon fontSize='large' />
            </Link>
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled>
          <ArrowForwardIosRoundedIcon fontSize='large' />
        </IconButton>
      )}
      {last > 0 ? (
        <Tooltip title='Τελευταια σελιδα'>
          <IconButton>
            <Link to={'/announcements/' + last} className={classes.simpleLink}>
              <LastPageRoundedIcon fontSize='large' />
            </Link>
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled>
          <LastPageRoundedIcon fontSize='large' />
        </IconButton>
      )}
    </div>
  );
};

export default Paginator;
