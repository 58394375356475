import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';
import TopBackground from '../components/topBackground/topBackground';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  mainWrapper: {
    overflowX: 'scroll',
    padding: 20
  }
});

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>
          Δε βρέθηκε η ιστοσελίδα | Ιστιοπλοϊκός Όμιλος Πατρών
        </title>
      </Helmet>
      <TopBackground />
      <Layout>
        <Paper className={classes.mainWrapper}>
          <h1>
              Η σελίδα δεν βρέθηκε
          </h1>
          <p>
            Το περιεχόμενο που ζητήσατε δεν βρέθηκε.
          </p>
        </Paper>
      </Layout>
    </div>
  );
};

export default PageNotFound;
