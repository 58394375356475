export const baseUrl = 'https://d.sailingclub.gr';

export const resultsPerPage = 5;

export const captchaSitekey = '6LeOX8UUAAAAAD3hakv6TZo3bI-hl2mgTCpM4G6K';

export const transformInnerHTML = (node) => {
  // Fix links to uploaded files
  if (
    node.type === 'tag' &&
    node.name === 'a' &&
    node.attribs.href &&
    node.attribs.href.substr(0, 6) === '/sites'
  ) {
    node.attribs.href = baseUrl + node.attribs.href;
    node.attribs.target = '_blank';
    return;
  }

  // Fix urls of uploaded images
  if (
    node.type === 'tag' &&
    node.name === 'img' &&
    node.attribs.src &&
    node.attribs.src.substr(0, 6) === '/sites'
  ) {
    node.attribs.src = baseUrl + node.attribs.src;
    return;
  }

  // Open external links in a new tab
  if (node.type === 'tag' && node.name === 'a') {
    node.attribs.target = '_blank';
    return;
  }
};

export const randomImage = () => {
  const numOfImgs = 12;
  const rnd = Math.floor(Math.random() * Math.floor(numOfImgs)) + 1;

  return require('../assets/img/backgrounds/' + rnd + '.jpg');
};

export const menuLinksSite = [
  {
    title: 'Όμιλος',
    submenu: [
      {
        url: '/history',
        name: 'Ιστορία',
        uuid: '6940a9d0-8316-40e9-9fdb-7ea36516eba3',
        basicPage: true,
      },
      {
        url: '/facilities',
        name: 'Εγκαταστάσεις',
        uuid: 'd89aa7f2-a273-48be-ba8b-c25cfc8b6948',
        basicPage: true,
      },
      {
        url: '/board',
        name: 'Δ.Σ.',
        uuid: 'a8336948-67e8-42e1-98d8-48c9523de752',
        basicPage: true,
      },
      {
        url: '/amea',
        name: 'Ιστιοπλοΐα ΑμεΑ',
        uuid: '954fd09d-295e-41a1-bb60-a0b28c95e848',
        basicPage: true,
      },
    ],
  },
  {
    title: 'Ενημέρωση',
    submenu: [
      {
        url: '/announcements',
        name: 'Ανακοινώσεις',
        uuid: '',
        basicPage: false,
      },
      //{ url: '/media', name: 'Media', uuid: '', basicPage: true },
      {
        url: '/links',
        name: 'Σύνδεσμοι',
        uuid: 'be770367-922b-457f-bfbc-3c7f7a3bc4ee',
        basicPage: true,
      },
      {
        url: '/forms',
        name: 'Χρήσιμα έντυπα',
        uuid: '2f56d531-7af8-4848-ad0e-dce2ab3d9475',
        basicPage: true,
      },
    ],
  },
  {
    title: 'Ιστιοπλοΐα Τριγώνου',
    submenu: [
      {
        url: '/academies',
        name: 'Ακαδημίες',
        uuid: '94e0dac7-c91a-4b29-9d0f-b0c84969979d',
        basicPage: true,
      },
      {
        url: '/groups',
        name: 'Αγωνιστικές Ομάδες',
        uuid: '422e3ce0-d7f4-4427-916e-4f55168375d8',
        basicPage: true,
      },
      {
        url: '/coaches',
        name: 'Προπονητές',
        uuid: '7104005c-5e63-40c0-af56-1e6b3235ae50',
        basicPage: true,
      },
      {
        url: '/races',
        name: 'Αγώνες',
        uuid: '06e85ef7-3b2b-4b3e-90f0-395228531471',
        basicPage: true,
      },
      {
        url: '/distinctions',
        name: 'Διακρίσεις Αθλητών',
        uuid: 'c3ecf012-c1f7-4d3e-a7f6-b2755b8f0bf9',
        basicPage: true,
      },
    ],
  },
  {
    url: '/amea',
    name: 'Ιστιοπλοΐα ΑμεΑ',
    uuid: '954fd09d-295e-41a1-bb60-a0b28c95e848',
    basicPage: true,
  },
  {
    url: '/school',
    name: 'Σχολή Ανοιχτής Θάλασσας',
    uuid: '',
    basicPage: false,
  },
  {
    title: 'Αγώνες Ανοιχτής Θάλασσας',
    submenu: [
      {
        url: '/raceprogram',
        name: 'Πρόγραμμα αγώνων',
        uuid: 'e1050d99-ed9e-4652-b4fb-81bb3aba2ea6',
        basicPage: true,
      },
      {
        url: '/ionion-week',
        name: 'Διεθνής Ιστιοπλοϊκή Εβδομάδα Ιονίου',
        uuid: '4fa53d9b-6e60-4ada-9d0c-73dc94e92249',
        basicPage: true,
      },
      {
        url: '/results',
        name: 'Αποτελέσματα',
        uuid: '69ef8871-581b-4982-b2a9-b38d6702ccb5',
        basicPage: true,
      },
      {
        url: '/archive',
        name: 'Αρχείο Αγώνων',
        uuid: 'd0b84750-2290-41ef-8344-94501ab6e805',
        basicPage: true,
      },
    ],
  },
  {
    url: '/ionion-week',
    name: 'Rally Ιονίου',
    uuid: '4fa53d9b-6e60-4ada-9d0c-73dc94e92249',
    basicPage: true,
  },
  {
    url: 'https://sailingclub.gr/iqfoil/',
    name: 'IQ FOIL 2023',
    isExternal: true,
  },
  {
    url: '/contact',
    name: 'Επικοινωνία',
    uuid: '',
    basicPage: false,
  },
  {
    url: '/terms',
    name: 'Όροι χρήσης',
    uuid: '7cee21d6-554c-41e8-976e-03f19f36a94c',
    basicPage: true,
    noshow: true,
  },
];

export const menuLinksSchool = [
  { url: '/school', name: 'Αρχική', uuid: '', basicPage: false },
  { url: '/school/news', name: 'Νέα/Ανακοινώσεις', uuid: '', basicPage: false },
  {
    url: '/school/registration',
    name: 'Εγγραφή',
    uuid: '',
    basicPage: true,
    noshow: true,
  },
  {
    url: '/school/history',
    name: 'Ιστορικό',
    uuid: 'c0500084-66f0-4ee7-9b1d-9ef18e6da501',
    basicPage: true,
  },
  {
    url: '/school/instructors',
    name: 'Εκπαιδευτές/Σκάφη',
    uuid: '28ae7559-4fd8-4bb3-8c70-d3db68c0605d',
    basicPage: true,
  },

  { url: '/school/contact', name: 'Επικοινωνία', uuid: '', basicPage: false },
];
