import React from 'react';
// components
import SchoolCourse from './schoolCourse';
// Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  outerContainer: {
    padding: '40px 0'
  },
  innerContainer: {
    padding: '0px 20px'
  }
});

const InfoSection = ({ courses }) => {
  const classes = useStyles();

  return (
    <Container className={classes.outerContainer} maxWidth='lg'>
      <Grid className={classes.innerContainer} container>
        {courses.map((course, index) => {
          return (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <SchoolCourse
                title={course.title}
                body={course.body}
                image={course.image}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default InfoSection;
