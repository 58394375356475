import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  footer: {
    bottom: '0',
    backgroundColor: theme.palette.primary.darken,
    position: 'absolute',
    padding: '0 20px',
    width: '100%',
    height: '100px'
  },
  footerLeft: {
    color: '#99a9b5'
  },
  footerCenter: {
    textAlign: 'center'
  },
  footerRight: {
    textAlign: 'right'
  },
  navigation: {
    listStyleType: 'none',
    display: 'inline-block',
    margin: '0 20px'
  },
  navigationItem: {
    textDecoration: 'none',
    display: 'inline-block',
    padding: '0px 20px',
    position: 'relative',
    color: '#99a9b5',
    lineHeight: 1,
    '&:not(:first-child)': {
      borderLeft: '1px solid #99a9b5'
    }
  }
}));

const Footer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const matches = useMediaQuery('(max-width: 400px)');

  return (
    <Grid container alignItems='center' className={classes.footer}>
      <Grid item xs={3}>
        <div
          style={matches ? { fontSize: 14 } : null}
          className={classes.footerLeft}
        >
          Copyright &copy; {1900 + new Date().getYear()} Ιστιοπλοϊκός Όμιλος
          Πατρών
        </div>
      </Grid>
      <Grid item xs={6}>
        <div
          style={matches ? { fontSize: 14 } : null}
          className={classes.footerCenter}
        >
          <ul className={classes.navigation}>
            <Link to='/terms' className={classes.navigationItem}>
              <li>Όροι Χρήσης</li>
            </Link>
            <Link to='/contact' className={classes.navigationItem}>
              <li>Επικοινωνία</li>
            </Link>
          </ul>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.footerRight}>
          <a
            href='https://www.facebook.com/Ιστιοπλοϊκός-Όμιλος-Πατρών-ΙΟΠ-Patras-Sailing-Club-Patras-Sailing-Club-157708644295697/'
            target='_blank'
          >
            <FacebookIcon fontSize='large' color={'primary'} />
          </a>
          <a
            href='https://www.youtube.com/user/patrassailing/videos'
            target='_blank'
          >
            <YouTubeIcon fontSize='large' color={'secondary'} />
          </a>
          <a
            href='https://www.instagram.com/i.o.patron'
            target='_blank'
          >
            <InstagramIcon fontSize='large' color={'action'} />
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default Footer;
