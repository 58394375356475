import React, { Fragment } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import goldSponsorImg from '../../assets/img/goldSponsor.png';

const useStyles = makeStyles(theme => ({
  sponsorDiv: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 0',
    padding: '0 40px',
    borderRadius: '3px',
    display: 'inline-block'
  },
  sponsorWrapper: {
    position: 'relative',
    width: 'fit-content',
    margin: 'auto'
  },
  goldSponsor: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  goldSponsorLogo: {
    width: '50px'
  },
  sponsorImg: {
    maxWidth: '100%',
    maxHeight: '200px'
  },
  sponsorImgGolden: {
    maxWidth: '100%',
    maxHeight: '300px',
    boxShadow: '10px 10px 10px 0px rgba(0,0,0,0.75)'
  },
  sponsorDivider: {
    margin: '50px',
    border: 0,
    height: '1px',
    background:
      'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0))'
  }
}));

const Sponsor = ({ title, url, image, isGolden }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.sponsorDiv}>
        <a href={url} target='_blank' rel='noopener noreferrer'>
          <div
            className={classes.sponsorWrapper}
            title={isGolden ? 'Χρυσός χορηγός' : null}
          >
            {isGolden ? (
              <div className={classes.goldSponsor}>
                <img
                  className={classes.goldSponsorLogo}
                  src={goldSponsorImg}
                  alt='Χρυσός χορηγός'
                />
              </div>
            ) : null}
            <img
              className={
                isGolden ? classes.sponsorImgGolden : classes.sponsorImg
              }
              src={image}
              alt={title}
            />
          </div>
        </a>
      </div>
      {isGolden ? <hr className={classes.sponsorDivider} /> : null}
    </Fragment>
  );
};

export default Sponsor;
