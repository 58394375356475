import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CornerRibbon from '../cornerRibbon/cornerRibbon';
import defaultImg from '../../assets/img/defaultPost.jpg';

const useStyles = makeStyles({
  wrapper: {
    minHeight: 350
  },
  event: {
    marginBottom: '20px'
  },
  topEvent: {
    marginBottom: '40px',
    marginTop: '25px',
    boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    position: 'relative'
  },
  imageWrapper: {
    padding: '10px'
  },
  imageEvent: {
    borderRadius: '3px',
    objectFit: 'cover',
    width: '100%'
  },
  button: {
    width: '40%',
    margin: '20px 30% 0 30%'
  },
  post: {
    marginTop: '15px',
    padding: '10px 30px'
  },
  descriptionWrapper: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%)'
  },
  postTitle: {
    textAlign: 'center',
    marginBottom: 20
  },
  postDescription: {
    maxHeight: '130px',
    overflow: 'hidden'
  },
  btnLink: {
    textDecoration: 'none'
  }
});

const NewsCard = ({ uuid, title, image, description, isTop, type }) => {
  const classes = useStyles();

  return (
    <div className={isTop ? classes.topEvent : classes.event}>
      {isTop ? <CornerRibbon text={'HOT'} /> : ''}
      <Paper className={classes.paper}>
        <Grid
          container
          justify='center'
          alignItems='center'
          className={classes.wrapper}
        >
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <div className={classes.imageWrapper}>
              <img
                className={classes.imageEvent}
                src={image === null ? defaultImg : image}
              />
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={7} xs={12} className={classes.post}>
            <h3 className={classes.postTitle}>{title}</h3>
            <div className={classes.descriptionWrapper}>
              <div className={classes.overlay}></div>
              <div
                className={classes.postDescription}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <Link
              className={classes.btnLink}
              to={
                type === 'club' ? `/article/${uuid}` : `/school/article/${uuid}`
              }
            >
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
              >
                {' '}
                ΠΕΡΙΣΣΟΤΕΡΑ
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default NewsCard;
